import "./VideoPlayerV2.scss";
import {
  forwardRef,
  memo,
  useCallback,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import {
  formatTime,
  generateTimelineZoomLevels,
  getFrameDimensions,
  throttle,
} from "../../utils";
import { IVideo, TimelineZoomLevel } from "../../types";
import VideoTimeline, {
  TIMELINE_PADDING,
} from "../video-timeline/VideoTimeline";
import clipIcon from "./../../assets/icons/clip.svg";
import playIcon from "./../../assets/icons/play.svg";
import pauseIcon from "./../../assets/icons/pause.svg";
import plusIcon from "./../../assets/icons/plus.svg";
import minusIcon from "./../../assets/icons/minus.svg";
import replayIcon from "./../../assets/icons/replay.svg";
import Draggable, { DraggableData, DraggableEvent } from "react-draggable";
import { IClipConfig, ICropBox, IVideoSegment } from "../../remotion/types";
import Remotion from "../../pages/Remotion/Remotion";
import { useDispatch } from "react-redux";
import {
  setSubtitles,
  setSegments as setVideoSegments,
} from "../../features/clipConfig/clipConfigSlice";
import { LoadingOverlay, Tooltip } from "@mantine/core";
import { useVideoDimensions } from "../../hooks/useVideoDimensions";
import VFIconComponent from "../icon/vf-icon";

const VidfastPlayer = memo(
  forwardRef(
    (
      props: {
        video?: IVideo;
        vCutToolConfig: {
          aspectRatio: string;
        };
        isEdit: boolean;
      },
      ref
    ) => {
      const blurredVideoRef = useRef<HTMLVideoElement>(null),
        parentElementRef = useRef(null),
        currentTimeIndicatorRef = useRef(null),
        timelineContainerRef = useRef(null),
        remotionRef = useRef(null),
        currentTimeHolderRef = useRef(null);

      const videoPlayerRef = useRef<HTMLVideoElement>(null);

      const dispatch = useDispatch();

      const { dimensions, containerRef, calculateDimensions } =
        useVideoDimensions(blurredVideoRef);

      const getFrameDimension = (
        dimension: "width" | "height",
        aspectRatio?: string
      ): number => {
        const frameDimensions: { frameWidth: number; frameHeight: number } =
          getFrameDimensions(
            aspectRatio ? aspectRatio : props.vCutToolConfig.aspectRatio,
            {
              width: dimensions.width,
              height: dimensions.height,
            }
          );

        return dimension === "width"
          ? frameDimensions.frameWidth
          : frameDimensions.frameHeight;
      };

      const getCropBox = (): ICropBox | null => {
        return {
          x1: 0,
          x2: +getFrameDimension(
            "width",
            props.vCutToolConfig.aspectRatio
          ).toFixed(2),
          y1: 0,
          y2: +getFrameDimension(
            "height",
            props.vCutToolConfig.aspectRatio
          ).toFixed(2),
        };
      };

      useImperativeHandle(
        ref,
        () => ({
          getPayloadData() {
            return {
              editConfig,
            };
          },
          getCompositionDimensions() {
            return {
              width: dimensions.width,
              height: dimensions.height,
            };
          },
          getVideoRef() {
            return blurredVideoRef.current;
          },
          pauseVideo() {
            blurredVideoRef.current?.pause();
            setPlayButtonState("play");
          },
          resetPlayer() {
            blurredVideoRef.current?.pause();
            setPlayButtonState("play");
            setVideoPlayerCurrentTime(0);
            setCurrentSegment(null);
            createEditConfig();
          },
        }),
        [dimensions, blurredVideoRef]
      );

      let [videoWidth, setVideoWidth] = useState(0),
        [videoHeight, setVideoHeight] = useState(0),
        [videoDuration, setVideoDuration] = useState(
          props.video?.media_metadata.duration_seconds
        ),
        [playButtonState, setPlayButtonState] = useState("play"),
        [parentElementWidth, setParentElementWidth] = useState(0),
        [zoomLevel, setZoomLevel] = useState(null),
        [currentVideos, setCurrentVideos] = useState([] as any[]),
        [segments, setSegments] = useState<IVideoSegment[]>([]),
        [updatedSegments, setUpdatedSegments] = useState<IVideoSegment[]>([]),
        [currentSegment, setCurrentSegment] = useState<IVideoSegment | null>(
          null
        ),
        [pixelsPerSecond, setPixelsPerSecond] = useState(0),
        [
          isVideoCurrentTimeIndicatorDragging,
          setIsVideoCurrentTimeIndicatorDragging,
        ] = useState(false),
        [timelineScrollLeft, setTimelineScrollLeft] = useState(0),
        [timelineScrollThumbSize, setTimelineScrollThumbSize] = useState(0),
        [editConfig, setEditConfig] = useState<Partial<IClipConfig>>(),
        [isVideoLoading, setIsVideoLoading] = useState(true),
        [zoomLevels, setZoomLevels] = useState<TimelineZoomLevel[]>([]);

      const isVideoCurrentTimeIndicatorDraggingRef = useRef(
        isVideoCurrentTimeIndicatorDragging
      );

      const segmentsRef = useRef(segments);

      useEffect(() => {
        isVideoCurrentTimeIndicatorDraggingRef.current =
          isVideoCurrentTimeIndicatorDragging;
      }, [isVideoCurrentTimeIndicatorDragging]);

      useEffect(() => {
        setEditConfig((prevConfig: IClipConfig) => {
          return {
            ...prevConfig,
            compositionDimensions: {
              width: dimensions.width,
              height: dimensions.height,
            },
          };
        });
      }, [dimensions]);
      const handleFrame = useCallback(
        (now, metadata) => {
          const currentTime = +metadata.mediaTime.toFixed(3);
          const frameNumber = currentTime * props?.video.media_metadata.fps;

          remotionRef.current?.seekTo(frameNumber);

          if (currentTimeHolderRef.current)
            currentTimeHolderRef.current.innerHTML = `${formatTime(
              currentTime
            )}/${formatTime(videoDuration)}`;

          const offset = calculateTimelineOffset();

          if (!isVideoCurrentTimeIndicatorDraggingRef.current) {
            setCurrentTimeIndicatorPosition({
              x: +(currentTime * pixelsPerSecond - offset).toFixed(3),
              y: -10,
            });
          }

          if (segmentsRef.current.length && playButtonState === "play") {
            if (
              currentTime >=
              segmentsRef.current[segmentsRef.current.length - 1].end
            ) {
              blurredVideoRef.current?.pause();

              setPlayButtonState("play");
            }
          }

          // Continue the callback loop
          blurredVideoRef.current?.requestVideoFrameCallback(handleFrame);
        },
        [
          segments,
          pixelsPerSecond,
          timelineScrollLeft,
          isVideoCurrentTimeIndicatorDragging,
        ]
      );

      // Effect to set up the video frame callback
      useEffect(() => {
        const video = blurredVideoRef.current;
        if (video) {
          video.requestVideoFrameCallback(handleFrame);
        }

        // Cleanup function to possibly cancel the frame callback when the component unmounts or updates
        return () => {
          // Since there's no direct method to cancel requestVideoFrameCallback,
          // you might handle cleanup by using a flag or disconnecting the video element's src,
          // depending on your application's needs.
        };
      }, [handleFrame]);

      useEffect(() => {
        const isFrameVisible = props.vCutToolConfig.aspectRatio !== "original";

        const newCurrentSegment: IVideoSegment = {
          ...currentSegment,
          crop_box: isFrameVisible ? getCropBox() : null,
        };

        setCurrentSegment(newCurrentSegment);

        const newSegments: IVideoSegment[] = segments.map(
          (segment: IVideoSegment) => {
            return {
              ...segment,
              crop_box: isFrameVisible ? getCropBox() : null,
            };
          }
        );

        if (newSegments.length) {
          setSegments(newSegments);

          dispatch(setVideoSegments(newSegments));
        }
      }, [props.vCutToolConfig.aspectRatio]);

      useEffect(() => {
        if (props.video) {
          const mappedSegments: IVideoSegment[] = props.isEdit
            ? props.video.config.segments.map(
                (segment: IVideoSegment, index: number) => {
                  return { ...segment, text: `Video ${index + 1}` };
                }
              )
            : [
                {
                  start: 0,
                  end: props.video.media_metadata.duration_seconds,
                  crop_box: null,
                  text: props.video.title,
                },
              ];
          setSegments(mappedSegments);
          setCurrentSegment(mappedSegments[0]);
        }

        const handleWindowResize = () => {
          setParentElementWidth(parentElementRef?.current.offsetWidth);
        };

        window.addEventListener("resize", handleWindowResize);

        return () => {
          window.removeEventListener("resize", handleWindowResize);
        };
      }, []);

      useEffect(() => {
        const videoCurrentTime: number =
          +blurredVideoRef.current?.currentTime.toFixed(3);

        const newPosition = {
          x: +(
            videoCurrentTime * pixelsPerSecond -
            calculateTimelineOffset()
          ).toFixed(3),
          y: -10,
        };

        setCurrentTimeIndicatorPosition(newPosition);
      }, [timelineScrollLeft]);

      useEffect(() => {
        segmentsRef.current = segments;

        if (segments && segments.length) dispatch(setVideoSegments(segments));
      }, [segments]);

      useEffect(() => {
        const resizeObserver = new ResizeObserver(() => {
          calculateDimensions();
        });

        if (containerRef.current) {
          resizeObserver.observe(containerRef.current);
        }

        return () => {
          resizeObserver.disconnect();
        };
      }, []);

      const calculateTimelineOffset = (): number => {
        return (
          (timelineScrollLeft *
            props.video?.media_metadata.duration_seconds *
            pixelsPerSecond) /
          (document.body.clientWidth - 50)
        );
      };

      const handleCurrentTimeIndicatorDrag = useCallback(
        throttle((e, position) => {
          e.preventDefault();
          e.stopPropagation();

          // this is the position of the indicator which is placed by transform css property
          const x = getCurrentTimeIndicatorXPosition();
          const newCurrentTime = +(
            (x + calculateTimelineOffset()) /
            pixelsPerSecond
          ).toFixed(3);

          setVideoPlayerCurrentTime(newCurrentTime);

          const fps = props?.video.media_metadata.fps;
          const preciseFps =
            Math.abs(fps - Math.round(fps)) < 0.05 ? Math.round(fps) : fps;

          // Calculate the frame index more accurately
          const frame = newCurrentTime * preciseFps;
          remotionRef.current?.seekTo(frame);
        }, 100), // Adjust the delay as needed
        [timelineScrollLeft, pixelsPerSecond, props?.video.media_metadata.fps]
      );

      const setVideoPlayerCurrentTime = (currentTime: number): void => {
        blurredVideoRef && blurredVideoRef.current
          ? (blurredVideoRef.current.currentTime = currentTime)
          : null;
      };

      const handleZoomChange = useCallback(
        (newZoomLevel: number): void => {
          setZoomLevel(newZoomLevel);
          calculateTimelineScrollThumbSize({ zoomLevel: newZoomLevel });

          setTimeout(() => {
            calculateTimelineScrollLeft({ zoomLevel: newZoomLevel });
          }, 100);
        },
        [
          zoomLevel,
          zoomLevels,
          parentElementRef,
          parentElementWidth,
          pixelsPerSecond,
          timelineScrollLeft,
          videoDuration,
          timelineScrollThumbSize,
        ]
      );

      const splitClip = () => {
        const x = getCurrentTimeIndicatorXPosition();
        const videoCurrentTime: number = +(
          (x + calculateTimelineOffset()) /
          pixelsPerSecond
        ).toFixed(3);

        // Dont allow split if current time is exactly at the start or end of a segment
        const isSplitAllowed: boolean = !segments.some(
          (segment: IVideoSegment) =>
            videoCurrentTime === segment.start ||
            videoCurrentTime === segment.end
        );

        if (!isSplitAllowed) return;

        const newSegment: IVideoSegment = {
          ...currentSegment,
          start: +videoCurrentTime,
          end: currentSegment.end,
          text: `Segment ${segments.length + 1}`, // TODO: Static for now!
        };

        setCurrentSegment((prevSegment: IVideoSegment) => {
          return {
            ...prevSegment,
            end: +videoCurrentTime,
          };
        });

        const newSegments: IVideoSegment[] = [...segments, newSegment]
          .sort((a: IVideoSegment, b: IVideoSegment) => a.start - b.start)
          .map((segment: IVideoSegment, index: number) => {
            if (segment.text === currentSegment?.text) {
              return {
                ...currentSegment,
                end: +videoCurrentTime,
              };
            }

            return segment;
          });

        setSegments(newSegments);
      };

      const deleteSegment = () => {
        const newSegments: IVideoSegment[] = segments.filter(
          (segment: IVideoSegment) => segment.text !== currentSegment?.text
        );

        setSegments(newSegments);

        setCurrentSegment(null);

        setVideoPlayerCurrentTime(newSegments[0].start);
      };

      const configUpdate = useCallback(
        (config: Partial<IClipConfig>) => {
          setEditConfig((prevConfig: IClipConfig) => {
            return {
              ...prevConfig,
              ...config,
            };
          });

          if (config.segments) {
            setSegments(config.segments);
            dispatch(setVideoSegments(config.segments));
          }

          if (config.subtitles && config.subtitles.position)
            dispatch(setSubtitles({ position: config.subtitles?.position }));
        },
        [props]
      );

      const createEditConfig = (): void => {
        setEditConfig({
          sourceVideo: { ...props.video },
          renderMode: false,
          compositionDimensions: {
            width: dimensions.width,
            height: dimensions.height,
          },
          videoElement: blurredVideoRef.current,
          configUpdate: configUpdate,
        });

        const newSegments: IVideoSegment[] = [
          {
            start: currentSegment?.start || 0,
            end: currentSegment?.end || videoDuration,
            text: props.video?.title,
            crop_box: null,
          },
        ];

        setSegments(newSegments);

        setCurrentSegment(newSegments[0]);

        dispatch(setVideoSegments(newSegments));
      };

      const videoPlayerTimeUpdate = (e) => {
        const newTime: number = +e.target?.currentTime.toFixed(3);

        const currentTimeIndicatorXPosition =
          getCurrentTimeIndicatorXPosition();

        if (
          currentTimeIndicatorXPosition >= document.body.clientWidth - 50 ||
          currentTimeIndicatorXPosition < 0
        ) {
          calculateTimelineScrollLeft({ zoomLevel });
        }

        segments.map((segment: IVideoSegment) => {
          if (
            newTime >= segment.start &&
            newTime <= segment.end &&
            segment.text !== currentSegment?.text
          ) {
            setCurrentSegment(segment);
          }

          return segment;
        });
      };
      const videoPlayerMetadataLoad = useCallback(() => {
        setVideoWidth(blurredVideoRef.current?.videoWidth);
        setVideoHeight(blurredVideoRef.current?.videoHeight);

        setParentElementWidth(parentElementRef?.current.offsetWidth);

        const zoomLevels = generateTimelineZoomLevels(
          blurredVideoRef.current?.duration,
          document.body.clientWidth - 50
        );

        const defaultZoomLevelIndex = zoomLevels.find(
          (level) => level.default
        )?.index;

        setZoomLevels(zoomLevels);

        setZoomLevel(defaultZoomLevelIndex);

        calculateTimelineScrollThumbSize({
          zoomLevel: defaultZoomLevelIndex,
          zoomLevels,
          parentElementWidth: document.body.clientWidth - 50,
        });

        setCurrentVideos([
          {
            title: "Video 1",
            duration: videoDuration,
          },
        ]);

        createEditConfig();

        setIsVideoLoading(false);
      }, [videoDuration, parentElementRef, parentElementWidth, dimensions]);

      const segmentsResize = useCallback(
        (items: any | any, itemsType: string) => {
          if (itemsType === "videos") {
            setSegments([...items]);

            setCurrentSegment((prevSegment: IVideoSegment) => {
              segments.map((segment: IVideoSegment) => {
                if (segment.text === prevSegment?.text) {
                  setCurrentSegment(segment);
                }
              });
            });
          }
        },
        [segments, currentSegment]
      );

      const calculateTimelineScrollThumbSize = (config?: {
        zoomLevel: number;
        zoomLevels?: TimelineZoomLevel[];
        parentElementWidth?: number;
      }): void => {
        const currentZoomLevel = (
          config && config.zoomLevels && config.zoomLevels.length
            ? config.zoomLevels
            : zoomLevels
        ).find(
          (level) =>
            level.index ===
            (config && config.zoomLevel !== undefined
              ? config.zoomLevel
              : zoomLevel)
        );

        const numberOfIntervals = Math.ceil(
          videoDuration / currentZoomLevel?.interval
        );

        const parentWidth =
          config && config.parentElementWidth
            ? config.parentElementWidth
            : document.body.clientWidth - 50;

        const scrollThumbSize =
          (parentWidth /
            (numberOfIntervals * currentZoomLevel.spaceBetweenIntervals)) *
            parentWidth -
          TIMELINE_PADDING * 2;

        setTimelineScrollThumbSize(scrollThumbSize);
      };

      const pixelsPerSecondChange = useCallback(
        (pxPerSecond: number) => {
          if (pxPerSecond !== pixelsPerSecond) {
            const offset = calculateTimelineOffset();
            const videoCurrentTime: number =
              +blurredVideoRef.current?.currentTime.toFixed(3);

            setPixelsPerSecond(pxPerSecond);
            setCurrentTimeIndicatorPosition({
              x: +(videoCurrentTime * pxPerSecond - offset).toFixed(3),
              y: -10,
            });
          }
        },
        [blurredVideoRef.current, pixelsPerSecond]
      );

      const getCurrentTimeIndicatorXPosition = (): number => {
        const computedStyle = window.getComputedStyle(
          currentTimeIndicatorRef?.current
        );

        const transformMatrix = computedStyle.transform;

        let translateX: number = 0;

        if (transformMatrix && transformMatrix !== "none") {
          const matrixValues = transformMatrix
            .match(/matrix.*\((.+)\)/)[1]
            .split(", ");

          translateX = parseFloat(matrixValues[4]);
        }

        return translateX;
      };

      const setCurrentTimeIndicatorPosition = (position: {
        x: number;
        y: number;
      }) => {
        if (currentTimeIndicatorRef.current)
          currentTimeIndicatorRef.current.style.transform = `translate(${position.x}px, ${position.y}px)`;
      };

      const onTimeUpdate = useCallback(
        (newTime: number) => {
          // const frameNumber = newTime * props?.video.media_metadata.fps;
          // remotionRef.current?.seekTo(frameNumber);
          setVideoPlayerCurrentTime(newTime);
        },
        [blurredVideoRef.current, remotionRef.current]
      );

      const replayButtonHandler = useCallback(() => {
        const newTime = segments[0].start;

        const frameNumber = Math.floor(
          newTime * props?.video.media_metadata.fps
        );
        remotionRef.current?.seekTo(frameNumber);
        setVideoPlayerCurrentTime(newTime);
      }, [remotionRef.current, segments]);

      const calculateTimelineScrollLeft = (config: {
        zoomLevel: number;
      }): void => {
        if (config.zoomLevel === 0) return setTimelineScrollLeft(0);

        const currentZoomLevel = zoomLevels.find(
          (level) => level.index === zoomLevel
        );

        const numberOfIntervals = Math.ceil(
          videoDuration / currentZoomLevel?.interval
        );

        const totalTimelineLength =
          numberOfIntervals * currentZoomLevel.spaceBetweenIntervals;

        const newScrollLeft =
          (+blurredVideoRef.current?.currentTime.toFixed(3) *
            pixelsPerSecond *
            (document.body.clientWidth - 50)) /
          totalTimelineLength;

        setTimelineScrollLeft(newScrollLeft);
      };

      return (
        <div ref={parentElementRef}>
          <LoadingOverlay
            visible={isVideoLoading}
            loaderProps={{ size: "lg", variant: "dots" }}
          />
          <div>
            <div
              className="video-container"
              ref={containerRef}
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <video
                ref={blurredVideoRef}
                id="blurredVideo"
                className="blurred-video"
                onTimeUpdate={videoPlayerTimeUpdate}
                onLoadedMetadata={videoPlayerMetadataLoad}
                style={{
                  width: dimensions.width || "100%",
                  height: dimensions.height || "auto",
                  display: "block",
                  boxSizing: "border-box",
                  border: "none",
                  objectFit: "contain",
                }}
              >
                <source src={props.video?.file_path} type="video/mp4" />
              </video>
              <div
                style={{
                  position: "absolute",
                  display: "block",
                  zIndex: 20,
                }}
                className="remotion-holder"
              >
                <Remotion ref={remotionRef} clipConfig={editConfig} />
              </div>
            </div>
          </div>
          <div
            className="controls-and-timeline-holder"
            style={{
              position: "absolute",
              left: 0,
              right: 0,
              bottom: -200,
              padding: "25px",
            }}
          >
            <div className="video-controls-container">
              <div className="left-section">
                {/* <div className="circle-icon-holder">
              <img src={undoIcon} alt="undo" />
            </div>
            <div className="circle-icon-holder">
              <img src={redoIcon} alt="redo" />
            </div> */}

                <div className="clip-icon-holder" onClick={splitClip}>
                  <img src={clipIcon} alt="clip" />
                  <span>Split Clip</span>
                </div>
                {segments.length > 1 && (
                  <Tooltip
                    withArrow
                    transition="fade"
                    transitionDuration={350}
                    label="This will delete the segment you're currently at"
                  >
                    <div
                      className="delete-segment-button"
                      onClick={deleteSegment}
                    >
                      <VFIconComponent
                        type="delete-filled"
                        size={15}
                        style={{ marginRight: "10px" }}
                      />
                      <span>Delete Segment</span>
                    </div>
                  </Tooltip>
                )}
              </div>
              <div className="center-section">
                {/* <div className="circle-icon-holder">
              <img src={prevIcon} alt="prev" />
            </div> */}
                <div
                  className="circle-icon-holder large"
                  onClick={() => {
                    blurredVideoRef && blurredVideoRef.current
                      ? blurredVideoRef?.current[playButtonState]()
                      : null;

                    setPlayButtonState(
                      playButtonState === "play" ? "pause" : "play"
                    );
                  }}
                >
                  <img
                    src={playButtonState === "play" ? playIcon : pauseIcon}
                    alt="play"
                  />
                </div>
                <div
                  className="circle-icon-holder large"
                  onClick={replayButtonHandler}
                >
                  <img className="replay-icon" src={replayIcon} alt="next" />
                </div>
              </div>
              <div className="right-section">
                <div className="zoom-controls-holder">
                  <img
                    src={minusIcon}
                    className="zoom-out-icon"
                    alt="next"
                    onClick={() => {
                      const newZoomLevel: number = zoomLevel - 1;

                      if (zoomLevel !== 0) handleZoomChange(newZoomLevel);
                    }}
                  />
                  <input
                    type="range"
                    min={0}
                    max={zoomLevels.length - 1}
                    value={zoomLevel}
                    onChange={(event) => {
                      handleZoomChange(+event.target.value);
                    }}
                  />
                  <img
                    src={plusIcon}
                    className="zoom-in-icon"
                    alt="next"
                    onClick={() => {
                      if (zoomLevel < zoomLevels.length - 1)
                        handleZoomChange(zoomLevel + 1);
                    }}
                  />
                </div>
                {blurredVideoRef && blurredVideoRef.current && (
                  <span
                    className="current-time-holder"
                    ref={currentTimeHolderRef}
                  >
                    {formatTime(blurredVideoRef.current.currentTime)}/
                    {formatTime(videoDuration)}
                  </span>
                )}
              </div>
            </div>
            <div className="timeline-container" ref={timelineContainerRef}>
              <Draggable
                axis="x"
                defaultPosition={{ x: 0, y: -10 }}
                bounds={{
                  top: -10,
                  left: 0,
                  bottom: 20,
                  right: document.body.clientWidth - 60,
                }}
                position={{
                  x: currentTimeIndicatorRef.current
                    ? getCurrentTimeIndicatorXPosition()
                    : 0,
                  y: -10,
                }}
                onDrag={handleCurrentTimeIndicatorDrag}
                onStart={() => setIsVideoCurrentTimeIndicatorDragging(true)}
                onStop={() => setIsVideoCurrentTimeIndicatorDragging(false)}
              >
                <div
                  ref={currentTimeIndicatorRef}
                  className="current-time-indicator"
                  style={{
                    transition: isVideoCurrentTimeIndicatorDragging
                      ? "none"
                      : "transform 0.15s ease-out",
                  }}
                >
                  <div className="playhead-icon"></div>
                </div>
              </Draggable>

              <VideoTimeline
                videos={segments.map((segment: IVideoSegment) => {
                  return {
                    ...segment,
                    active: segment.text === currentSegment?.text,
                  };
                })}
                videoDuration={videoDuration}
                onItemResize={segmentsResize}
                canvasWidth={document.body.clientWidth - 50}
                timelineZoomLevel={zoomLevel}
                onPixelPerSecondChange={pixelsPerSecondChange}
                scrollLeft={timelineScrollLeft}
                onTimeUpdated={onTimeUpdate}
                zoomLevels={zoomLevels}
              />

              <div className="timeline-scroll-thumb-holder">
                <Draggable
                  axis="x"
                  defaultPosition={{ x: 0, y: 0 }}
                  position={{ x: timelineScrollLeft, y: 0 }}
                  bounds={"parent"}
                  onDrag={(e: DraggableEvent, position: DraggableData) => {
                    const { x } = position;

                    setTimelineScrollLeft(x);
                  }}
                >
                  <div
                    className="timeline-scroll-thumb"
                    style={{
                      width: `${timelineScrollThumbSize}px`,
                    }}
                  ></div>
                </Draggable>
              </div>
            </div>
          </div>
        </div>
      );
    }
  )
);

VidfastPlayer.displayName = "VidfastPlayer";

export default VidfastPlayer;
