import React, { useState, memo, useCallback, useEffect, useRef } from "react";
import "./CaptionsList.scss";
import { ICaption } from "../../types";
import CaptionsSeparator from "../captions-separator/CaptionsSeparator";
import SubtitleItem from "../subtitle-item/SubtitleItem";
import { ISubtitle } from "../../remotion/types";
import { VariableSizeList as List } from "react-window";

interface CaptionsListProps {
  captions: ISubtitle[];
  onChange: (newCaptions: ICaption[]) => void;
  videoPlayerRef: any;
}

const LIST_HEIGHT = 460;
const LIST_WIDTH = 338;

const CaptionsList: React.FC<CaptionsListProps> = memo(
  ({ captions, onChange, videoPlayerRef }) => {
    const listRef = useRef<List>(null);
    const [currentTime, setCurrentTime] = useState(0);
    const scrollAnimationRef = useRef<number | null>(null);
    const itemHeights = useRef<number[]>([]);

    const calculateItemHeight = useCallback(
      (index: number) => {
        const caption = captions[index];
        const baseHeight = 60;
        const lineHeight = 20;
        const textLines = Math.ceil(caption.text.length / 40);
        const height = baseHeight + (textLines - 1) * lineHeight;
        return height + (index < captions.length - 1 ? 28 : 0);
      },
      [captions]
    );

    const getItemHeight = useCallback(
      (index: number) => {
        if (!itemHeights.current[index]) {
          itemHeights.current[index] = calculateItemHeight(index);
        }

        return 114;
      },
      [calculateItemHeight]
    );

    const handleTimeUpdate = useCallback(() => {
      if (videoPlayerRef.current) {
        const videoElement = videoPlayerRef.current.getVideoRef();
        if (videoElement) {
          setCurrentTime(videoElement.currentTime);
        }
      }
    }, [videoPlayerRef]);

    useEffect(() => {
      if (videoPlayerRef.current) {
        const videoElement = videoPlayerRef.current.getVideoRef();
        if (videoElement) {
          videoElement.addEventListener("timeupdate", handleTimeUpdate);
          return () =>
            videoElement.removeEventListener("timeupdate", handleTimeUpdate);
        }
      }
    }, [videoPlayerRef, handleTimeUpdate]);

    const findCurrentSubtitleIndex = useCallback(() => {
      if (!captions) return -1;

      return captions.findIndex(
        (caption) => currentTime >= caption.start && currentTime <= caption.end
      );
    }, [currentTime, captions]);

    const getTotalHeight = useCallback(
      (index: number) => {
        let totalHeight = 0;
        for (let i = 0; i < index; i++) {
          totalHeight += getItemHeight(i);
        }
        return totalHeight;
      },
      [getItemHeight]
    );

    const isItemInView = useCallback(
      (index: number) => {
        if (!listRef.current) return false;

        const listScrollTop = listRef.current.state.scrollOffset;
        const itemTop = getTotalHeight(index);
        const itemBottom = itemTop + getItemHeight(index);

        return (
          itemTop >= listScrollTop && itemBottom <= listScrollTop + LIST_HEIGHT
        );
      },
      [getTotalHeight, getItemHeight]
    );

    const smoothScroll = useCallback(
      (targetIndex: number) => {
        if (!listRef.current || isItemInView(targetIndex)) return;

        const startScrollTop = listRef.current.state.scrollOffset;
        const targetItemTop = getTotalHeight(targetIndex);
        const targetItemBottom = targetItemTop + getItemHeight(targetIndex);
        const middleOffset = LIST_HEIGHT / 2;

        let targetScrollTop;
        if (targetItemBottom - targetItemTop < LIST_HEIGHT) {
          targetScrollTop =
            targetItemTop -
            middleOffset +
            (targetItemBottom - targetItemTop) / 2;
        } else {
          targetScrollTop = targetItemTop;
        }

        targetScrollTop = Math.max(
          0,
          Math.min(
            targetScrollTop,
            getTotalHeight(captions.length) - LIST_HEIGHT
          )
        );

        const distance = targetScrollTop - startScrollTop;
        const duration = 300;
        let startTime: number | null = null;

        const animateScroll = (timestamp: number) => {
          if (!startTime) startTime = timestamp;
          const elapsed = timestamp - startTime;
          const progress = Math.min(elapsed / duration, 1);
          const easeProgress = 0.5 - Math.cos(progress * Math.PI) / 2;

          const newScrollTop = startScrollTop + distance * easeProgress;
          listRef.current?.scrollTo(newScrollTop);

          if (progress < 1) {
            scrollAnimationRef.current = requestAnimationFrame(animateScroll);
          }
        };

        if (scrollAnimationRef.current) {
          cancelAnimationFrame(scrollAnimationRef.current);
        }
        scrollAnimationRef.current = requestAnimationFrame(animateScroll);
      },
      [getItemHeight, getTotalHeight, captions.length, isItemInView]
    );

    useEffect(() => {
      const currentIndex = findCurrentSubtitleIndex();
      if (currentIndex !== -1) {
        smoothScroll(currentIndex);
      }
    }, [currentTime, findCurrentSubtitleIndex, smoothScroll]);

    const subtitleUpdate = useCallback(
      (updatedSubtitle: ISubtitle, action: "delete" | "update") => {
        let updatedSubtitles: ISubtitle[];

        if (action === "delete") {
          updatedSubtitles = captions.filter(
            (sub) =>
              `${sub.start}-${sub.end}` !==
              `${updatedSubtitle.start}-${updatedSubtitle.end}`
          );
        } else if (action === "update") {
          updatedSubtitles = captions.map((sub) =>
            `${sub.start}-${sub.end}` ===
            `${updatedSubtitle.start}-${updatedSubtitle.end}`
              ? updatedSubtitle
              : sub
          );
        }

        onChange(updatedSubtitles);
        itemHeights.current = [];
        listRef.current?.resetAfterIndex(0);
      },
      [captions, onChange]
    );

    const Row = memo(({ index, style }) => (
      <div key={index} style={style}>
        <SubtitleItem
          key={index}
          subtitle={captions[index]}
          onUpdate={subtitleUpdate}
          isActive={
            currentTime >= captions[index].start &&
            currentTime <= captions[index].end
          }
        />
        {index !== captions.length - 1 && (
          <CaptionsSeparator
            handleCaptionAction={(action: string) =>
              handleCaptionAction(action, captions[index], index)
            }
          />
        )}
      </div>
    ));

    const handleCaptionAction = useCallback(
      (action: string, caption: ISubtitle, index: number): void => {
        if (action === "merge") {
          const nextCaption = captions[index + 1];
          const newCaption = {
            ...caption,
            start: caption.start,
            end: nextCaption.end,
            text: `${caption.text} ${nextCaption.text}`,
            words: [...caption.words, ...nextCaption.words],
          };

          const updatedSubtitles = captions
            .map((sub, subIndex) => (subIndex === index ? newCaption : sub))
            .filter((sub, subIndex) => subIndex !== index + 1);

          onChange(updatedSubtitles);
          itemHeights.current = [];
          listRef.current?.resetAfterIndex(0);
        }
      },
      [captions, onChange]
    );

    return (
      <div className="captions-list">
        {captions && captions.length > 0 ? (
          <List
            height={LIST_HEIGHT}
            itemCount={captions.length}
            itemSize={getItemHeight}
            width={LIST_WIDTH}
            ref={listRef}
            overscanCount={5}
          >
            {Row}
          </List>
        ) : (
          <div className="no-captions">
            <p>No subtitles found</p>
          </div>
        )}
      </div>
    );
  }
);

CaptionsList.displayName = "CaptionsList";

export default CaptionsList;
