import {
  Alert,
  Button,
  LoadingOverlay,
  Modal,
  TextInput,
  Transition,
  createStyles,
} from "@mantine/core";
import confirmClipIcon from "./../../assets/icons/clip-confirm.svg";
import closeIcon from "./../../assets/icons/white-close-button.svg";
import acceptIcon from "./../../assets/icons/small-accept.svg";
import rejectIcon from "./../../assets/icons/small-reject.svg";
import editIcon from "./../../assets/icons/edit.svg";
import successIcon from "./../../assets/icons/success.svg";
import {
  formatTime,
  getClipDurationInSeconds,
  pushToDataLayer,
} from "../../utils";
import "./ConfirmClipModal.scss";
import { memo, useCallback, useEffect, useState } from "react";
import { ICreateClipPayload } from "../../types";
import { store } from "../../store";
import { createClip } from "../../features/videos/videosSlice";
import { useNavigate } from "react-router-dom";
import VFIconComponent from "../icon/vf-icon";
import { useErrorHandler } from "../../hooks/useErrorHandler";

const LABEL_ICON_SIZE = 18;

interface ConfirmClipModalProps {
  setShowModal: (show: boolean) => void;
  resetEditor: () => void;
  clipPayload: ICreateClipPayload;
  videoId: string;
  aspectRatio: string;
  shown: boolean;
}

const useStyles = createStyles(() => ({
  title: {
    fontWeight: "bold",
    fontSize: "24px",
    textAlign: "center",
    margin: "0 auto",
  },
  body: {
    overflow: "hidden",
  },
  root: {
    textAlign: "center",
    marginTop: 35,
    minHeight: 100,
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  inner: {
    ".mantine-Paper-root": {
      borderRadius: 10,
    },
  },
}));

const ConfirmClipModal: React.FC<ConfirmClipModalProps> = memo(
  ({ setShowModal, clipPayload, videoId, aspectRatio, shown, resetEditor }) => {
    const { classes } = useStyles();

    const [title, setTitle] = useState(clipPayload?.title);
    const [isEditingTitle, setIsEditingTitle] = useState(false);
    const [clipProcessingInitiated, setClipProcessingInitiated] =
      useState(false);

    const [isLoading, setIsLoading] = useState(false);

    const navigate = useNavigate();

    const { handleError } = useErrorHandler();

    useEffect(() => {
      setTitle(clipPayload?.title);

      // resets
      setClipProcessingInitiated(false);
      setIsEditingTitle(false);
    }, [clipPayload]);

    const clipDurationInSeconds: number = getClipDurationInSeconds(
      clipPayload?.segments
    );

    const clipDurationFormatted: string = formatTime(
      clipDurationInSeconds,
      true
    );

    const userSubscriptionLevel: number =
      store.getState().auth.userData?.subscription_level;

    const clipDurationExceedsLimit: boolean =
      (userSubscriptionLevel === 0 || userSubscriptionLevel === 1) &&
      clipDurationInSeconds > 120;

    const hasSubtitles: boolean = !!clipPayload?.subtitles;

    const exportClip = useCallback(async () => {
      try {
        setIsLoading(true);

        pushToDataLayer("clip_exported", {
          email: store.getState().auth.userData?.email,
        });

        await store
          .dispatch(
            createClip({
              videoId,
              clipPayload: {
                ...clipPayload,
                title,
              },
            })
          )
          .unwrap();

        setIsLoading(false);

        setClipProcessingInitiated(true);
      } catch (response: any) {
        setIsLoading(false);

        setShowModal(false);

        handleError(response.error, response.statusCode);
      }
    }, [clipPayload, title, videoId]);

    const startNewClip = useCallback(() => {
      resetEditor();
      setShowModal(false);
    }, []);

    const editTitleContent = (
      <div className="edit-title-holder">
        <TextInput
          size="sm"
          value={title}
          onChange={(event) => setTitle(event.currentTarget.value)}
          styles={{
            input: {
              "&:focus": {
                borderColor: "#29B1A1",
              },
            },
            root: {
              marginRight: "5px",
            },
          }}
        />

        <img
          className="confirm-title-button"
          src={acceptIcon}
          alt="Confirm title button"
          onClick={() => setIsEditingTitle(!isEditingTitle)}
        />
        <img
          className="reject-title-button"
          src={rejectIcon}
          alt="Reject title button"
          onClick={() => {
            setTitle(clipPayload?.title);
            setIsEditingTitle(!isEditingTitle);
          }}
        />
      </div>
    );

    const successScreenContent = (
      <>
        <div className="icon-holder">
          <img
            src={successIcon}
            className="success-clip-export-icon"
            alt="Success clip export icon"
          />
        </div>
        <div className="confirm-clip-modal-header">
          <h1 className="title">Clip Export Complete!</h1>
          <p className="subtitle">
            Your clip has been successfully sent for processing. Would you like
            to create another one?
          </p>
        </div>
        <div className="confirm-clip-modal-actions">
          <Button className="vf-button-filled" size="md" onClick={startNewClip}>
            Start New Clip
          </Button>
          <Button
            className="vf-button-outlined"
            size="md"
            variant="outline"
            onClick={() => navigate("./../clips")}
          >
            Not now
          </Button>
        </div>
      </>
    );

    return (
      <Modal
        classNames={{
          root: classes.root,
          title: classes.title,
          body: classes.body,
          inner: classes.inner,
        }}
        transition="fade"
        transitionDuration={500}
        transitionTimingFunction="ease"
        opened={shown}
        onClose={() => setShowModal(false)}
        centered={true}
        overlayOpacity={0.55}
        overlayBlur={3}
        size={650}
        closeOnClickOutside={false}
        closeOnEscape={false}
        padding={0}
        withCloseButton={false}
      >
        <LoadingOverlay
          visible={isLoading}
          loaderProps={{ size: "lg", variant: "dots" }}
        />
        <div className="style-header">
          <img
            className="close-button"
            src={closeIcon}
            alt="Close button"
            onClick={() => setShowModal(false)}
          />
        </div>

        <div className="content">
          {!clipProcessingInitiated && (
            <>
              <div className="icon-holder">
                <img
                  src={confirmClipIcon}
                  className="confirm-clip-icon"
                  alt="confirm-clip-icon"
                />
              </div>
              <div className="confirm-clip-modal-header">
                <h1 className="title">Clip export</h1>
                <p className="subtitle">
                  You are about to export a clip with the following options:
                </p>
              </div>
              {clipDurationExceedsLimit && (
                <div className="confirm-clip-modal-warning-holder">
                  <Alert
                    icon={
                      <VFIconComponent type="error" color="#fa5252" size={16} />
                    }
                    variant="light"
                    color="red"
                    title="Duration Limit Reached"
                    classNames={{}}
                  >
                    <div className="warning-body">
                      Your clip is exceeding the{" "}
                      <span className="bold">2-minute limit</span> for your
                      current plan. <br />
                      <span className="bold">Upgrade to Pro</span> for unlimited
                      duration or trim your segments.
                    </div>
                  </Alert>
                </div>
              )}
              <div className="confirm-clip-modal-body">
                <div className="info-line">
                  <div className="info-label">
                    <VFIconComponent
                      className="label-icon"
                      color="#29b1a1"
                      type="text"
                      size={LABEL_ICON_SIZE}
                    />
                    <span>Title:</span>
                  </div>
                  {!isEditingTitle && (
                    <div className="title-holder">
                      <span
                        className="clip-title"
                        onClick={() => setIsEditingTitle(!isEditingTitle)}
                      >
                        {title}
                      </span>
                      <img
                        className="edit-title-button"
                        src={editIcon}
                        alt="Edit title button"
                        onClick={() => setIsEditingTitle(!isEditingTitle)}
                      />
                    </div>
                  )}
                  {isEditingTitle && editTitleContent}
                </div>
                <div className="info-line">
                  <div className="info-label">
                    <VFIconComponent
                      className="label-icon"
                      color="#29b1a1"
                      type="clock"
                      size={LABEL_ICON_SIZE}
                    />
                    <span>Duration:</span>
                  </div>
                  <span>{clipDurationFormatted}</span>
                </div>
                <div className="info-line">
                  <div className="info-label">
                    <VFIconComponent
                      className="label-icon"
                      color="#29b1a1"
                      type="aspect-ratio"
                      size={LABEL_ICON_SIZE}
                    />
                    <span>Aspect Ratio:</span>
                  </div>
                  <span>{aspectRatio}</span>
                </div>
                <div className="info-line">
                  <div className="info-label">
                    <VFIconComponent
                      className="label-icon"
                      color="#29b1a1"
                      type="subtitles"
                      size={LABEL_ICON_SIZE}
                    />
                    <span>Subtitles:</span>
                  </div>
                  <VFIconComponent
                    type={hasSubtitles ? "success" : "error"}
                    size={20}
                    style={{ marginRight: "10px" }}
                  />
                </div>
              </div>
              <div className="confirm-clip-modal-actions">
                <Button
                  className="vf-button-filled"
                  size="md"
                  onClick={exportClip}
                  disabled={clipDurationExceedsLimit}
                >
                  Export
                </Button>
                <Button
                  className="vf-button-outlined"
                  size="md"
                  variant="outline"
                  onClick={() => setShowModal(false)}
                >
                  Cancel
                </Button>
              </div>
            </>
          )}

          <Transition
            mounted={clipProcessingInitiated}
            transition="slide-left"
            duration={500}
            timingFunction="ease"
          >
            {(styles) => (
              <div style={{ ...styles }} className="">
                {successScreenContent}
              </div>
            )}
          </Transition>
        </div>
      </Modal>
    );
  }
);

ConfirmClipModal.displayName = "ConfirmClipModal";

export default ConfirmClipModal;
