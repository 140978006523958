import { useState, useRef, useEffect } from "react";

export const useVideoDimensions = (
  videoRef: React.RefObject<HTMLVideoElement>
) => {
  const [dimensions, setDimensions] = useState({ width: 0, height: 0 });
  const containerRef = useRef<HTMLDivElement>(null);
  const maxHeight = window.innerHeight * 0.9; // 75vh
  const controlsHeight = 190; // pixels reserved for controls

  const calculateDimensions = () => {
    if (!videoRef.current || !containerRef.current) return;

    const video = videoRef.current;
    const container = containerRef.current;
    const containerWidth = container.offsetWidth;

    // Get video's natural aspect ratio
    const videoRatio = video.videoWidth / video.videoHeight;

    // Calculate maximum available height
    const availableHeight = maxHeight - controlsHeight;

    // Calculate dimensions maintaining aspect ratio
    let newWidth = containerWidth;
    let newHeight = containerWidth / videoRatio;

    // If calculated height exceeds available height, scale down
    if (newHeight > availableHeight) {
      newHeight = availableHeight;
      newWidth = availableHeight * videoRatio;
    }

    setDimensions({
      width: Math.floor(newWidth),
      height: Math.floor(newHeight),
    });
  };

  useEffect(() => {
    const handleResize = () => {
      calculateDimensions();
    };

    window.addEventListener("resize", handleResize);

    // Initial calculation
    if (videoRef.current?.readyState >= 1) {
      calculateDimensions();
    }

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  // Handle video metadata load
  useEffect(() => {
    const video = videoRef.current;
    if (!video) return;

    const handleLoadedMetadata = () => {
      calculateDimensions();
    };

    video.addEventListener("loadedmetadata", handleLoadedMetadata);

    return () => {
      video.removeEventListener("loadedmetadata", handleLoadedMetadata);
    };
  }, []);

  return { dimensions, containerRef, calculateDimensions };
};
