import "./Editor.scss";
import vidfastLogo from "./../../assets/images/vidfast-logo.svg";
import EditorOptions, {
  EditorOptionSelectedEvent,
} from "../../components/editor-options/EditorOptions";
import { useParams, useNavigate } from "react-router-dom";

import { ICreateClipPayload } from "../../types";
import { Button, LoadingOverlay, TextInput, createStyles } from "@mantine/core";
import CaptionsOptions from "../../components/captions-options/CaptionsOptions";
import { memo, useCallback, useEffect, useMemo, useRef, useState } from "react";
import Breadcrumbs from "../../components/Breadcrumbs";
import { UserDropdown } from "../../components/user-dropdown/UserDropdown";
import { RootState, store } from "../../store";
import { Video, selectVideoById } from "../../features/videos/videosSlice";
import { useSelector } from "react-redux";
import { filterSubtitles, getSubtitleDefaultSize } from "../../utils";
import exportIcon from "./../../assets/icons/export.svg";
import arrowLeftIcon from "./../../assets/icons/arrow-left.svg";
import { ICropBox, IVideoSegment } from "../../remotion/types";
import VidfastPlayer from "../../components/video-player/VideoPlayerV2";
import ConfirmClipModal from "../../components/confirm-clip-modal/ConfirmClipModal";
import { setSubtitles } from "../../features/clipConfig/clipConfigSlice";
import { useDispatch } from "react-redux";

const useStyles = createStyles((theme) => ({
  root: {
    margin: "10px 0",
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  label: {
    width: "40%",
    textAlign: "left",
  },
  input: {
    width: "100%",
    textAlign: "center",
    paddingLeft: "0 !important",
  },
  wrapper: {
    width: "150px",
  },
  switchTrack: {
    "&input:checked": {
      backgroundColor: "#29B1A1",
      borderColor: "#29B1A1",
    },
  },
  switchInput: {
    "&:checked+.mantine-Switch-track": {
      backgroundColor: "#29B1A1",
      borderColor: "#29B1A1",
    },
  },
  tabsList: {
    marginBottom: "15px",
    "button[data-active], button[data-active]:hover": {
      borderColor: "#29B1A1",
    },
  },
  textAreaRoot: {
    textAlign: "left",
  },
  radioButtonGroupRoot: {
    "[role=radiogroup]": {
      justifyContent: "space-around",
    },
  },
  exportButtonRoot: {
    backgroundColor: "#FFF",
    border: "2px solid #29B1A1",
    borderRadius: "15px",
    color: "#29B1A1",
    fontSize: "18px",
    lineHeight: "22px",
    marginBottom: "20px",
    padding: "12px",
    height: "auto",

    "&:hover": {
      backgroundColor: "#FFF",
      color: "#29B1A1",
      borderColor: "#29B1A1",
    },
  },
}));

const Editor = memo(() => {
  const { projectId, editId } = useParams<{
    projectId: string;
    editId: string;
  }>();
  const { classes } = useStyles();

  const navigate = useNavigate(),
    [title, setTitle] = useState("Untitled clip"),
    [isEdit, setIsEdit] = useState(false);

  const [sizeOptionValue, setSizeOptionValue] = useState<any>({
    value: "original",
    size: "original",
  });
  const [shouldShowCaptionsOptions, setShouldShowCaptionsOptions] =
    useState<boolean>(false);

  const video: Video = useSelector((state: RootState) => {
    return selectVideoById(state, projectId);
  });

  const dispatch = useDispatch();

  const [showClipConfirmModal, setShowClipConfirmModal] =
    useState<boolean>(false);

  const [clipPayload, setClipPayload] = useState<ICreateClipPayload>(null);

  const videosStatus = useSelector((state: any) => state.videos.status);
  const error = useSelector((state: any) => state.videos.error);

  const [segments, subtitles] = useSelector((state: RootState) => [
    state.clipConfig.segments,
    state.clipConfig.subtitles,
  ]);

  const isVideoPortrait = useMemo((): boolean => {
    return video
      ? video.media_metadata.height > video.media_metadata.width
      : false;
  }, []);

  const childRef = useRef<any>();

  const resetEditor = () => {
    setTitle("Untitled clip");
    setSizeOptionValue({
      value: "original",
      size: "original",
    });
    setShouldShowCaptionsOptions(false);
    setShowClipConfirmModal(false);
    setClipPayload(null);

    store.dispatch(
      setSubtitles({
        items: null,
      })
    );

    childRef?.current.resetPlayer();
  };

  const handleEditorOptionSelected = useCallback(
    (optionSelectedEvent: EditorOptionSelectedEvent): void => {
      switch (optionSelectedEvent.type) {
        case "clip-size":
          setSizeOptionValue(optionSelectedEvent.value as any);
          break;
        case "captions":
          setShouldShowCaptionsOptions(true);
          break;
        case "back":
          navigate(-1);
          break;
      }
    },
    []
  );

  const applyRatioToCropBox = (cropBox: ICropBox): ICropBox | null => {
    if (!cropBox) return null;

    const compositionDimensions = childRef?.current?.getCompositionDimensions();

    const ratio = compositionDimensions.width / video.media_metadata.width;
    const newCropBox = {} as ICropBox;

    for (const [key, value] of Object.entries(cropBox))
      newCropBox[key as keyof ICropBox] = +(value / ratio).toFixed(2);

    return newCropBox;
  };

  const getClipPayload = (): ICreateClipPayload | null => {
    if (!segments || !segments.length || !subtitles) return null;

    const totalStart = Math.min(
      ...segments.map((segment: IVideoSegment) => segment.start)
    );
    const totalEnd = Math.max(
      ...segments.map((segment: IVideoSegment) => segment.end)
    );

    const payload: ICreateClipPayload = {
      title,
      segments: segments.map((segment: IVideoSegment) => ({
        start: segment.start,
        end: segment.end,
        crop_box: segment.crop_box
          ? applyRatioToCropBox(segment.crop_box)
          : null,
      })),
      ...(subtitles &&
        subtitles.items && {
          subtitles: {
            ...subtitles,
            items:
              subtitles && subtitles.items
                ? filterSubtitles(subtitles.items, totalStart, totalEnd)
                : null,
          },
        }),
    };

    return payload;
  };

  const backButtonClick = useCallback(() => {
    setShouldShowCaptionsOptions(false);
  }, []);

  const loadingContent = (
    <LoadingOverlay
      visible={true}
      loaderProps={{ size: "lg", variant: "dots" }}
    />
  );

  const errorContent = <span>{error}</span>;

  const pageContent = (
    <div className="editor-page-holder">
      <ConfirmClipModal
        clipPayload={clipPayload}
        videoId={projectId as string}
        setShowModal={setShowClipConfirmModal}
        resetEditor={resetEditor}
        aspectRatio={sizeOptionValue.size}
        shown={showClipConfirmModal}
      />
      <div
        className="sidebar-and-player-holder"
        style={{
          position: "relative",
        }}
      >
        <div className="editor-sidebar-holder">
          <div className="logo-holder" onClick={() => navigate("./projects")}>
            <img src={vidfastLogo} alt="Logo" />
            <span>
              vidfast<span className="top-level-domain">.ai</span>
            </span>
          </div>
          <div className="title-holder">
            {shouldShowCaptionsOptions && (
              <div className="back-button-holder" onClick={backButtonClick}>
                <img src={arrowLeftIcon} alt="Back" />
                <span className="button-title">Back</span>
              </div>
            )}
            <span className="title">
              {shouldShowCaptionsOptions ? "Subtitles" : "Editor"}
            </span>
          </div>
          <div className="options-holder">
            {!shouldShowCaptionsOptions ? (
              <EditorOptions
                isVideoPortrait={isVideoPortrait}
                handleOptionChange={handleEditorOptionSelected}
                subtitles={subtitles}
                video={video}
                activeState={{
                  clipSize: sizeOptionValue,
                  subtitles: false,
                }}
              />
            ) : (
              childRef &&
              childRef.current && (
                <CaptionsOptions
                  isEdit={isEdit}
                  videoId={projectId}
                  subtitles={
                    subtitles && subtitles.items && subtitles.items.length
                      ? subtitles.items
                      : video?.subtitles
                  }
                  videoPlayerRef={childRef}
                />
              )
            )}
          </div>
          <Button
            fullWidth
            onClick={() => {
              const payload: ICreateClipPayload = getClipPayload();

              setClipPayload(payload);

              setShowClipConfirmModal(true);

              // Pause video while exporting
              if (childRef && childRef.current) {
                childRef.current.pauseVideo();
              }
            }}
            leftIcon={<img src={exportIcon} alt="Export" />}
            classNames={{ root: classes.exportButtonRoot }}
            loaderProps={{ color: "#29b1a1", size: "sm" }}
          >
            Export Clip
          </Button>
        </div>
        <div className="video-player-section-holder">
          <div className="title-section">
            <div className="breadcrumbs-section-holder">
              <Breadcrumbs alwaysShow={true} inEditor={true} />
            </div>
            <div className="clip-title-holder">
              <TextInput
                size="sm"
                value={title}
                onChange={(event) => setTitle(event.currentTarget.value)}
                style={{ width: "200px" }}
                styles={{
                  input: {
                    "&:focus": {
                      borderColor: "#29B1A1",
                    },
                  },
                }}
              />
            </div>
            <div className="user-dropdown-section-holder">
              <UserDropdown />
            </div>
          </div>

          <VidfastPlayer
            ref={childRef}
            video={video}
            isEdit={isEdit}
            vCutToolConfig={{
              aspectRatio: sizeOptionValue.size,
            }}
          />
        </div>
      </div>
    </div>
  );

  useEffect(() => {
    if (video) {
      const subtitleDefaultSize: number = video
        ? getSubtitleDefaultSize(video.media_metadata.height)
        : 70;

      dispatch(
        setSubtitles({
          config: { ...subtitles?.config, size: subtitleDefaultSize },
        })
      );
    }
  }, [dispatch, video]);

  return (
    (videosStatus === "loading" && loadingContent) ||
    (videosStatus === "failed" && errorContent) ||
    pageContent
  );
});

Editor.displayName = "Editor";

export default Editor;
